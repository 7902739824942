const jsn = {
  1: {
    1: "Geneza 1-3; Matei 1",
    2: "Geneza 4-6; Matei 2",
    3: "Geneza 7-9; Matei 3",
    4: "Geneza 10-12; Matei 4",
    5: "Geneza 13-15; Matei 5:1-26",
    6: "Geneza 16-17; Matei 5:27-48",
    7: "Geneza 18-19; Matei 6:1-18",
    8: "Geneza 20-22; Matei 6:19-34",
    9: "Geneza 23-24; Matei 7",
    10: "Geneza 25-26; Matei 8:1-17",
    11: "Geneza 27-28; Matei 8:18-34",
    12: "Geneza 29-30; Matei 9:1-17",
    13: "Geneza 31-32; Matei 9:18-38",
    14: "Geneza 33-35; Matei 10:1-20",
    15: "Geneza 36-38; Matei 10:21-42",
    16: "Geneza 39-40; Matei 11",
    17: "Geneza 41-42; Matei 12:1-23",
    18: "Geneza 43-45; Matei 12:24-50",
    19: "Geneza 46-48; Matei 13:1-30",
    20: "Geneza 49-50; Matei 13:31-58",
    21: "Exod 1-3; Matei 14:1-21",
    22: "Exod 4-6; Matei 14:22-36",
    23: "Exod 7-8; Matei 15:1-20",
    24: "Exod 9-11; Matei 15:21-39",
    25: "Exod 12-13; Matei 16",
    26: "Exod 14-15; Matei 17",
    27: "Exod 16-18; Matei 18:1-20",
    28: "Exod 19-20; Matei 18:21-35",
    29: "Exod 21-22; Matei 19",
    30: "Exod 23-24; Matei 20:1-16 ",
    31: "Exod 25-26; Matei 20:17-34",
  },
  2: {
    1: "Exod 27-28; Matei 21:1-22",
    2: "Exod 29-30; Matei 21:23-46",
    3: "Exod 31-33; Matei 22:1-22",
    4: "Exod 34-35; Matei 22:23-46",
    5: "Exod 36-38; Matei 23:1-22",
    6: "Exod 39-40; Matei 23:23-39",
    7: "Leviticul 1-3; Matei 24:1-28",
    8: "Leviticul 4-5; Matei 24:29-51",
    9: "Leviticul 6-7; Matei 25:1-30",
    10: "Leviticul 8-10; Matei 25:31-46",
    11: "Leviticul 11-12; Matei 26:1-25",
    12: "Leviticul 13; Matei 26:26-50",
    13: "Leviticul 14; Matei 26:51-75",
    14: "Leviticul 15-16; Matei 27:1-26",
    15: "Leviticul 17-18; Matei 27:27-50",
    16: "Leviticul 19-20; Matei 27:51-66",
    17: "Leviticul 21-22; Matei 28",
    18: "Leviticul 23-24; Marcu 1:1-22",
    19: "Leviticul 25; Marcu 1:23-45",
    20: "Leviticul 26-27; Marcu 2",
    21: "Numeri 1-2; Marcu 3:1-19",
    22: "Numeri 3-4; Marcu 3:20-35",
    23: "Numeri 5-6; Marcu 4:1-20",
    24: "Numeri 7-8; Marcu 4:21-41",
    25: "Numeri 9-11; Marcu 5:1-20",
    26: "Numeri 12-14; Marcu 5:21-43",
    27: "Numeri 15-16; Marcu 6:1-29",
    28: "Numeri 17-19; Marcu 6:30-56",
    29: "Numeri 17-19; Marcu 6:30-56",
  },
  3: {
    1: "Numeri 20-22; Marcu 7:1-13",
    2: "Numeri 23-25; Marcu 7:14-37",
    3: "Numeri 26-28; Marcu 8",
    4: "Numeri 29-31; Marcu 9:1-29",
    5: "Numeri 32-34; Marcu 9:30-50",
    6: "Numeri 35-36; Marcu 10:1-31",
    7: "Deuteronomul 1-3; Marcu 10:32-52",
    8: "Deuteronomul 4-6; Marcu 11:1-18",
    9: "Deuteronomul 7-9; Marcu 11:19-33",
    10: "Deuteronomul 10-12; Marcu 12:1-27",
    11: "Deuteronomul 13-15; Marcu 12:28-44",
    12: "Deuteronomul 16-18; Marcu 13:1-20",
    13: "Deuteronomul 19-21; Marcu 13:21-37",
    14: "Deuteronomul 22-24; Marcu 14:1-26",
    15: "Deuteronomul 25-27; Marcu 14:27-53",
    16: "Deuteronomul 28-29; Marcu 14:54-72",
    17: "Deuteronomul 30-31; Marcu 15:1-25 ",
    18: "Deuteronomul 32-34; Marcu 15:26-47 ",
    19: "Iosua 1-3; Marcu 16 ",
    20: "Iosua 4-6; Luca 1:1-20 ",
    21: "Iosua 7-9; Luca 1:21-38 ",
    22: "Iosua 10-12; Luca 1:39-56 ",
    23: "Iosua 13-15; Luca 1:57-80 ",
    24: "Iosua 16-18; Luca 2:1-24 ",
    25: "Iosua 19-21; Luca 2:25-52 ",
    26: "Iosua 22-24; Luca 3",
    27: "Judecatorii 1-3; Luca 4:1-30 ",
    28: "Judecatorii 4-6; Luca 4:31-44 ",
    29: "Judecatorii 7-8; Luca 5:1-16 ",
    30: "Judecatorii 9-10; Luca 5:17-39 ",
    31: "Judecatorii 11-12; Luca 6:1-26 ",
  },
  4: {
    1: "Judecatorii 13-15; Luca 6:27-49 ",
    2: "Judecatorii 16-18; Luca 7:1-30 ",
    3: "Judecatorii 19-21; Luca 7:31-50 ",
    4: "Rut 1-4; Luca 8:1-25 ",
    5: "1Samuel 1-3; Luca 8:26-56 ",
    6: "1Samuel 4-6; Luca 9:1-17 ",
    7: "1Samuel 7-9; Luca 9:18-36 ",
    8: "1Samuel 10-12; Luca 9:37-62 ",
    9: "1Samuel 13-14; Luca 10:1-24 ",
    10: "1Samuel 15-16; Luca 10:25-42 ",
    11: "1Samuel 17-18; Luca 11:1-28 ",
    12: "1Samuel 19-21; Luca 11:29-54 ",
    13: "1Samuel 22-24; Luca 12:1-31 ",
    14: "1Samuel 25-26; Luca 12:32-59 ",
    15: "1Samuel 27-29; Luca 13:1-22 ",
    16: "1Samuel 30-31; Luca 13:23-35 ",
    17: "2Samuel 1-2; Luca 14:1-24 ",
    18: "2Samuel 3-5; Luca 14:25-35 ",
    19: "2Samuel 6-8; Luca 15:1-10 ",
    20: "2Samuel 9-11; Luca 15:11-32 ",
    21: "2Samuel 12-13; Luca 16 ",
    22: "2Samuel 14-15; Luca 17:1-19 ",
    23: "2Samuel 16-18; Luca 17:20-37 ",
    24: "2Samuel 19-20; Luca 18:1-23 ",
    25: "2Samuel 21-22; Luca 18:24-43 ",
    26: "2Samuel 23-24; Luca 19:1-27 ",
    27: "1Regi 1-2; Luca 19:28-48 ",
    28: "1Regi 3-5; Luca 20:1-26 ",
    29: "1Regi 6-7; Luca 20:27-47 ",
    30: "1Regi 8-9; Luca 21:1-19",
  },
  5: {
    1: "1Regi 10-11; Luca 21:20-38",
    2: "1Regi 12-13; Luca 22:1-30 ",
    3: "1Regi 14-15; Luca 22:31-46",
    4: "1Regi 16-18; Luca 22:47-71",
    5: "1Regi 19-20; Luca 23:1-25",
    6: "1Regi 21-22; Luca 23:26-56",
    7: "2Regi 1-3; Luca 24:1-35",
    8: "2Regi 4-6; Luca 24:36-53",
    9: "2Regi 7-9; Ioan 1:1-28",
    10: "2Regi 10-12; Ioan 1:29-51",
    11: "2Regi 13-14; Ioan 2",
    12: "2Regi 15-16; Ioan 3:1-18",
    13: "2Regi 17-18; Ioan 3:19-36",
    14: "2Regi 19-21; Ioan 4:1-30",
    15: "2Regi 22-23; Ioan 4:31-54",
    16: "2Regi 24-25; Ioan 5:1-24",
    17: "1Cronici 1-3; Ioan 5:25-47",
    18: "1Cronici 4-6; Ioan 6:1-21",
    19: "1Cronici 7-9; Ioan 6:22-44",
    20: "1Cronici 10-12; Ioan 6:45-71",
    21: "1Cronici 13-15; Ioan 7:1-27",
    22: "1Cronici 16-18; Ioan 7:28-53",
    23: "1Cronici 19-21; Ioan 8:1-27",
    24: "1Cronici 22-24; Ioan 8:28-59",
    25: "1Cronici 25-27; Ioan 9:1-23",
    26: "1Cronici 28-29; Ioan 9:24-41",
    27: "2Cronici 1-3; Ioan 10:1-23",
    28: "2Cronici 4-6; Ioan 10:24-42",
    29: "2Cronici 7-9; Ioan 11:1-29",
    30: "2Cronici 10-12; Ioan 11:30-57",
    31: "2Cronici 13-14; Ioan 12:1-26",
  },
  6: {
    1: "2Cronici 15-16; Ioan 12:27-50",
    2: "2Cronici 17-18; Ioan 13:1-20",
    3: "2Cronici 19-20; Ioan 13:21-38",
    4: "2Cronici 21-22; Ioan 14",
    5: "2Cronici 23-24; Ioan 15",
    6: "2Cronici 25-27; Ioan 16",
    7: "2Cronici 28-29; Ioan 17",
    8: "2Cronici 30-31; Ioan 18:1-18",
    9: "2Cronici 32-33; Ioan 18:19-40",
    10: "2Cronici 34-36; Ioan 19:1-22",
    11: "Ezra 1-2; Ioan 19:23-42",
    12: "Ezra 3-5; Ioan 20",
    13: "Ezra 6-8; Ioan 21",
    14: "Ezra 9-10; Fapte 1",
    15: "Neemia 1-3; Fapte 2:1-21",
    16: "Neemia 4-6; Fapte 2:22-47",
    17: "Neemia 7-9; Fapte 3",
    18: "Neemia 10-11; Fapte 4:1-22",
    19: "Neemia 12-13; Fapte 4:23-37",
    20: "Estera 1-2; Fapte 5:1-21",
    21: "Estera 3-5; Fapte 5:22-42",
    22: "Estera 6-8; Fapte 6",
    23: "Estera 9-10; Fapte 7:1-21",
    24: "Iov 1-2; Fapte 7:22-43",
    25: "Iov 3-4; Fapte 7:44-60",
    26: "Iov 5-7; Fapte 8:1-25",
    27: "Iov 8-10; Fapte 8:26-40",
    28: "Iov 11-13; Fapte 9:1-21",
    29: "Iov 14-16; Fapte 9:22-43",
    30: "Iov 17-19; Fapte 10:1-23",
  },
  7: {
    1: "Iov 20-21; Fapte 10:24-48",
    2: "Iov 22-24; Fapte 11",
    3: "Iov 25-27; Fapte 12",
    4: "Iov 28-29; Fapte 13:1-25",
    5: "Iov 30-31; Fapte 13:26-52",
    6: "Iov 32-33; Fapte 14",
    7: "Iov 34-35; Fapte 15:1-21",
    8: "Iov 36-37; Fapte 15:22-41",
    9: "Iov 38-40; Fapte 16:1-21",
    10: "Iov 41-42; Fapte 16:22-40",
    11: "Psalmii 1-3; Fapte 17:1-15",
    12: "Psalmii 4-6; Fapte 17:16-34",
    13: "Psalmii 7-9; Fapte 18",
    14: "Psalmii 10-12; Fapte 19:1-20",
    15: "Psalmii 13-15; Fapte 19:21-41",
    16: "Psalmii 16-17; Fapte 20:1-16",
    17: "Psalmii 18-19; Fapte 20:17-38",
    18: "Psalmii 20-22; Fapte 21:1-17",
    19: "Psalmii 23-25; Fapte 21:18-40",
    20: "Psalmii 26-28; Fapte 22",
    21: "Psalmii 29-30; Fapte 23:1-15",
    22: "Psalmii 31-32; Fapte 23:16-35",
    23: "Psalmii 33-34; Fapte 24",
    24: "Psalmii 35-36; Fapte 25",
    25: "Psalmii 37-39; Fapte 26",
    26: "Psalmii 40-42; Fapte 27:1-26",
    27: "Psalmii 43-45; Fapte 27:27-44",
    28: "Psalmii 46-48; Fapte 28",
    29: "Psalmii 49-50; Romani 1",
    30: "Psalmii 51-53; Romani 2",
    31: "Psalmii 54-56; Romani 3",
  },
  8: {
    1: "Psalmii 57-59; Romani 4",
    2: "Psalmii 60-62; Romani 5",
    3: "Psalmii 63-65; Romani 6",
    4: "Psalmii 66-67; Romani 7",
    5: "Psalmii 68-69; Romani 8:1-21",
    6: "Psalmii 70-71; Romani 8:22-39",
    7: "Psalmii 72-73; Romani 9:1-15",
    8: "Psalmii 74-76; Romani 9:16-33",
    9: "Psalmii 77-78; Romani 10",
    10: "Psalmii 79-80; Romani 11:1-18",
    11: "Psalmii 81-83; Romani 11:19-36",
    12: "Psalmii 84-86; Romani 12",
    13: "Psalmii 87-88; Romani 13",
    14: "Psalmii 89-90; Romani 14",
    15: "Psalmii 91-93; Romani 15:1-13",
    16: "Psalmii 94-96; Romani 15:14-33",
    17: "Psalmii 97-99; Romani 16",
    18: "Psalmii 100-102; 1Corinteni 1",
    19: "Psalmii 103-104; 1Corinteni 2",
    20: "Psalmii 105-106; 1Corinteni 3",
    21: "Psalmii 107-109; 1Corinteni 4",
    22: "Psalmii 110-112; 1Corinteni 5",
    23: "Psalmii 113-115; 1Corinteni 6",
    24: "Psalmii 116-118; 1Corinteni 7:1-19",
    25: "Psalmii 119:1-88; 1Corinteni 7:20-40",
    26: "Psalmii 119:89-176; 1Corinteni 8",
    27: "Psalmii 120-122; 1Corinteni 9",
    28: "Psalmii 123-125; 1Corinteni 10:1-18",
    29: "Psalmii 126-128; 1Corinteni 10:19-33",
    30: "Psalmii 129-131; 1Corinteni 11:1-16",
    31: "Psalmii 132-134; 1Corinteni 11:17-34",
  },
  9: {
    1: "Psalmii 135-136; 1Corinteni 12",
    2: "Psalmii 137-139; 1Corinteni 13",
    3: "Psalmii 140-142; 1Corinteni 14:1-20",
    4: "Psalmii 143-145; 1Corinteni 14:21-40",
    5: "Psalmii 146-147; 1Corinteni 15:1-28",
    6: "Psalmii 148-150; 1Corinteni 15:29-58",
    7: "Proverbe 1-2; 1Corinteni 16",
    8: "Proverbe 3-5; 2Corinteni 1",
    9: "Proverbe 6-7; 2Corinteni 2",
    10: "Proverbe 8-9; 2Corinteni 3",
    11: "Proverbe 10-12; 2Corinteni 4",
    12: "Proverbe 13-15; 2Corinteni 5",
    13: "Proverbe 16-18; 2Corinteni 6",
    14: "Proverbe 19-21; 2Corinteni 7",
    15: "Proverbe 22-24; 2Corinteni 8",
    16: "Proverbe 25-26; 2Corinteni 9",
    17: "Proverbe 27-29; 2Corinteni 10",
    18: "Proverbe 30-31; 2Corinteni 11:1-15",
    19: "Eclesiastul 1-3; 2Corinteni 11:16-33",
    20: "Eclesiastul 4-6; 2Corinteni 12",
    21: "Eclesiastul 7-9; 2Corinteni 13",
    22: "Eclesiastul 10-12; Galateni 1",
    23: "Cantarea-Cantarilor 1-3; Galateni 2",
    24: "Cantarea-Cantarilor 4-5; Galateni 3",
    25: "Cantarea-Cantarilor 6-8; Galateni 4",
    26: "Isaia 1-2; Galateni 5",
    27: "Isaia 3-4; Galateni 6",
    28: "Isaia 5-6; Efeseni 1",
    29: "Isaia 7-8; Efeseni 2",
    30: "Isaia 9-10; Efeseni 3",
  },
  10: {
    1: "Isaia 11-13; Efeseni 4",
    2: "Isaia 14-16; Efeseni 5:1-16",
    3: "Isaia 17-19; Efeseni 5:17-33",
    4: "Isaia 20-22; Efeseni 6",
    5: "Isaia 23-25; Filipeni 1",
    6: "Isaia 26-27; Filipeni 2",
    7: "Isaia 28-29; Filipeni 3",
    8: "Isaia 30-31; Filipeni 4",
    9: "Isaia 32-33; Coloseni 1",
    10: "Isaia 34-36; Coloseni 2",
    11: "Isaia 37-38; Coloseni 3",
    12: "Isaia 39-40; Coloseni 4",
    13: "Isaia 41-42; 1Tesaloniceni 1",
    14: "Isaia 43-44; 1Tesaloniceni 2",
    15: "Isaia 45-46; 1Tesaloniceni 3",
    16: "Isaia 47-49; 1Tesaloniceni 4",
    17: "Isaia 50-52; 1Tesaloniceni 5",
    18: "Isaia 53-55; 2Tesaloniceni 1",
    19: "Isaia 56-58; 2Tesaloniceni 2",
    20: "Isaia 59-61; 2Tesaloniceni 3",
    21: "Isaia 62-64; 1Timotei 1",
    22: "Isaia 65-66; 1Timotei 2",
    23: "Ieremia 1-2; 1Timotei 3",
    24: "Ieremia 3-5; 1Timotei 4",
    25: "Ieremia 6-8; 1Timotei 5",
    26: "Ieremia 9-11; 1Timotei 6",
    27: "Ieremia 12-14; 2Timotei 1",
    28: "Ieremia 15-17; 2Timotei 2",
    29: "Ieremia 18-19; 2Timotei 3",
    30: "Ieremia 20-21; 2Timotei 4",
    31: "Ieremia 22-23; Tit 1",
  },
  11: {
    1: "Ieremia 24-26; Tit 2",
    2: "Ieremia 27-29; Tit 3",
    3: "Ieremia 30-31; Filimon",
    4: "Ieremia 32-33; Evrei 1",
    5: "Ieremia 34-36; Evrei 2",
    6: "Ieremia 37-39; Evrei 3",
    7: "Ieremia 40-42; Evrei 4",
    8: "Ieremia 43-45; Evrei 5",
    9: "Ieremia 46-47; Evrei 6",
    10: "Ieremia 48-49; Evrei 7",
    11: "Ieremia 50; Evrei 8",
    12: "Ieremia 51-52; Evrei 9",
    13: "Plangerile lui Ieremia 1-2; Evrei 10:1-18",
    14: "Plangerile lui Ieremia 3-5; Evrei 10:19-39",
    15: "Ezechiel 1-2; Evrei 11:1-19",
    16: "Ezechiel 3-4; Evrei 11:20-40",
    17: "Ezechiel 5-7; Evrei 12",
    18: "Ezechiel 8-10; Evrei 13",
    19: "Ezechiel 11-13; Iacov 1",
    20: "Ezechiel 14-15; Iacov 2",
    21: "Ezechiel 16-17; Iacov 3",
    22: "Ezechiel 18-19; Iacov 4",
    23: "Ezechiel 20-21; Iacov 5",
    24: "Ezechiel 22-23; 1Petru 1",
    25: "Ezechiel 24-26; 1Petru 2",
    26: "Ezechiel 27-29; 1Petru 3",
    27: "Ezechiel 30-32; 1Petru 4",
    28: "Ezechiel 33-34; 1Petru 5",
    29: "Ezechiel 35-36; 2Petru 1",
    30: "Ezechiel 37-39; 2Petru 2",
  },
  12: {
    1: "Ezechiel 40-41; 2Petru 3",
    2: "Ezechiel 42-44; 1Ioan 1",
    3: "Ezechiel 45-46; 1Ioan 2",
    4: "Ezechiel 47-48; 1Ioan 3",
    5: "Daniel 1-2; 1Ioan 4",
    6: "Daniel 3-4; 1Ioan 5",
    7: "Daniel 5-7; 2Ioan",
    8: "Daniel 8-10; 3Ioan",
    9: "Daniel 11-12; Iuda",
    10: "Osea 1-4; Apocalipsa 1",
    11: "Osea 5-8; Apocalipsa 2",
    12: "Osea 9-11; Apocalipsa 3",
    13: "Osea 12-14; Apocalipsa 4",
    14: "Ioel; Apocalipsa 5",
    15: "Amos 1-3; Apocalipsa 6",
    16: "Amos 4-6; Apocalipsa 7",
    17: "Amos 7-9; Apocalipsa 8",
    18: "Obadia; Apocalipsa 9",
    19: "Iona; Apocalipsa 10",
    20: "Mica 1-3; Apocalipsa 11",
    21: "Mica 4-5; Apocalipsa 12",
    22: "Mica 6-7; Apocalipsa 13",
    23: "Naum; Apocalipsa 14",
    24: "Habacuc; Apocalipsa 15",
    25: "Tefania; Apocalipsa 16",
    26: "Hagai; Apocalipsa 17",
    27: "Zaharia 1-4; Apocalipsa 18",
    28: "Zaharia 5-8; Apocalipsa 19",
    29: "Zaharia 9-12; Apocalipsa 20",
    30: "Zaharia 13-14; Apocalipsa 21",
    31: "Maleahi; Apocalipsa 22",
  },
};

const fmonth = {
  1: "Ianuarie",
  2: "Februarie",
  3: "Martie",
  4: "Aprilie",
  5: "Mai",
  6: "Iunie",
  7: "Iulie",
  8: "August",
  9: "Septembrie",
  10: "Octombrie",
  11: "Noiembrie",
  12: "Decembrie",
};
const fday = {
  0: "Duminica",
  1: "Luni",
  2: "Marti",
  3: "Miercuri",
  4: "Joi",
  5: "Vineri",
  6: "Sambata",
};

export const passage = (date = undefined) => {
  const currentDate = date || new Date();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const wday = currentDate.getDay();

  const forToday = jsn[month][day];

  return {
    forToday,
    display: {
      static: "Pasajul zile pentru:",
      date: `${fday[wday]} (${day} ${fmonth[month]})`,
    },
  };
};
