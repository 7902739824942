const mapper = {
  gn: ["Geneza"],
  ex: ["Exod"],
  lv: ["Leviticul"],
  nm: ["Numeri"],
  dt: ["Deuteronomul"],
  js: ["Iosua"],
  jud: ["Judecatorii"],
  rt: ["Rut"],
  "1sm": ["1Samuel"],
  "2sm": ["2Samuel"],
  "1rg": ["1Regi"],
  "2rg": ["2Regi"],
  "1cr": ["1Cronici"],
  "2cr": ["2Cronici"],
  ezr: ["Ezra"],
  ne: ["Neemia"],
  et: ["Estera"],
  iov: ["Iov"],
  ps: ["Psalmii"],
  prv: ["Proverbe"],
  ec: ["Eclesiastul"],
  cc: ["Cantarea-Cantarilor"],
  is: ["Isaia"],
  ir: ["Ieremia"],
  png: ["Plangerile lui Ieremia"],
  ez: ["Ezechiel"],
  dn: ["Daniel"],
  ho: ["Osea"],
  il: ["Ioel"],
  am: ["Amos"],
  ob: ["Obadia"],
  in: ["Iona"],
  mi: ["Mica"],
  na: ["Naum"],
  hc: ["Habacuc"],
  tf: ["Tefania"],
  hg: ["Hagai"],
  zh: ["Zaharia"],
  ml: ["Maleahi"],
  mt: ["Matei"],
  mc: ["Marcu"],
  lc: ["Luca"],
  io: ["Ioan"],
  fpt: ["Fapte"],
  rm: ["Romani"],
  "1co": ["1Corinteni"],
  "2co": ["2Corinteni"],
  gl: ["Galateni"],
  efe: ["Efeseni"],
  fp: ["Filipeni"],
  cl: ["Coloseni"],
  "1ts": ["1Tesaloniceni"],
  "2ts": ["2Tesaloniceni"],
  "1tm": ["1Timotei"],
  "2tm": ["2Timotei"],
  tt: ["Tit"],
  fil: ["Filimon"],
  ev: ["Evrei"],
  ib: ["Iacov"],
  "1pe": ["1Petru"],
  "2pe": ["2Petru"],
  "1io": ["1Ioan"],
  "2io": ["2Ioan"],
  "3io": ["3Ioan"],
  id: ["Iuda"],
  ap: ["Apocalipsa"],
};

const map = (val) => {
  for (let [key, value] of Object.entries(mapper)) {
    if (value.includes(val)) {
      return key;
    }
  }
};

export default map;
